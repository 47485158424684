import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AlertButton, AlertController } from '@ionic/angular/standalone';
import { TranslocoService } from '@jsverse/transloco';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { from, map, Observable, of, switchMap } from 'rxjs';

type ErrorActionCreator<T extends string> = ActionCreator<
    T,
    (props: { error: string }) => { error: string } & Action<T>
>;

export function isRetryableError(error: unknown) {
    return error instanceof HttpErrorResponse && 'error' in error.error;
}

@Injectable({ providedIn: 'root' })
export class ErrorService {
    private store = inject(Store);
    private alertController = inject(AlertController);
    private transloco = inject(TranslocoService);

    async showErrorDialog(error: unknown, failedAction?: Action | null, onRetry?: () => void) {
        console.log(error);
        const top = await this.alertController.getTop();
        if (top) {
            await top.dismiss();
        }
        const buttons: AlertButton[] = [
            {
                text: this.transloco.translate('CLOSE'),
                role: 'cancel',
            },
        ];
        if (isRetryableError(error) && failedAction) {
            buttons.push({
                text: this.transloco.translate('RETRY'),
                handler: () => this.store.dispatch(failedAction),
            });
        } else if (onRetry) {
            buttons.push({
                text: this.transloco.translate('RETRY'),
                handler: () => onRetry(),
            });
        }
        const dialog = await this.alertController.create({
            header: this.transloco.translate('ERROR'),
            message: this.getMessage(error),
            buttons,
        });
        await dialog.present();
        return dialog;
    }

    handleError<T extends string>(
        originalAction: Action,
        failActionCreator: ErrorActionCreator<T>,
        error: unknown,
        options?: { duration?: number; canRetry?: boolean },
    ): Observable<Action> {
        const retry = options?.canRetry ?? true;
        const clickAction = retry ? originalAction : null;
        const message = this.getMessage(error);
        const errorAction = failActionCreator({ error: message });
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
            // AuthInterceptor will have redirected us to the login page, so we don't need to show an error message
            return of(errorAction);
        }
        return from(this.showErrorDialog(message, clickAction)).pipe(
            switchMap((dialog) => from(dialog.onDidDismiss())),
            map(() => errorAction),
        );
    }

    getMessage(error: unknown): string {
        if (typeof error === 'string') {
            return error;
        } else if (error instanceof HttpErrorResponse && error.error && typeof error.error === 'object') {
            if ('message' in error.error) {
                const msg = error.error.message;
                if (typeof msg === 'string') {
                    return msg;
                } else if (Array.isArray(msg)) {
                    return msg.join(', ');
                }
            }
        }
        console.error(error);
        return this.transloco.translate('UNKNOWN_ERROR');
    }
}
